<template>
    <div class="not-found">
    	<div class="not-found-container">
          <img :src="imagePath" :alt="message">
          <div class="not-found-message">
              <h1>{{  message  }}</h1>
              <button class="back-button" @click="handleClick"> {{ buttonText }} </button>
          </div>
      </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

defineProps({
    message: {
        type: String,
        required: true,
        default: ''
    },
    imagePath: {
        type: String,
        required: true,
        default: ''
    },
    buttonText: {
        type: String,
        required: false,
        default: 'Go back to listing page'
    }
})

const emit = defineEmits(['click'])

function handleClick() {
    emit('click')
}
</script>

<style>
    .not-found {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 75vh;
        margin: 0;
        font-family: Arial, sans-serif;
        background-color: #f5f5f5;
        color: #333;
    }
    .not-found-container {
        text-align: center;
    }
    .not-found-container img {
        max-width: 100%;
        height: auto;
    }
    .not-found-message {
        margin-top: 20px;
        margin-bottom: 5px;
    }
    .not-found-message h1 {
        font-size: 2em;
        color: #7E8299;
        margin: 0;
    }
    .back-button {
        margin-top: 50px;
        padding: 10px 20px;
        font-size: 1.2em;
        color: #fff;
        background-color: #007bff;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-decoration: none;
    }
    .back-button:hover {
        background-color: #0056b3;
    }
</style>